/*
    Overrides mobile bugs
*/
input[type="search"]{
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

#job-description-section::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
}

#job-description-section::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
