body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.divide-y > :not(template) ~ :not(template) {
	border-bottom-width: 1px;
}

/*@keyframes skeleton {*/
/*  0%   { background-position: -100px; transform: translateX(-100%); }*/
/*  100% { background-position: 140px; transform: translateX(100%); }*/
/*}*/

/*@keyframes skeleton {*/
/*  100% {*/
/*    transform: background-position(100%);*/
/*  }*/
/*}*/

/*@keyframes skeleton {*/
/*  100% {*/
/*    transform: translateX(100%);*/
/*  }*/
/*}*/

/*.skeleton {*/
/*  border-radius: 2px;*/
/*  height: 20px;*/
/*  margin-top: 10px;*/
/*  margin-bottom: 10px;*/
/*  border-color: #EDF2F7 !important;*/
/*  box-shadow: none !important;*/
/*  opacity: 0.7;*/
/*  background: #EDF2F7;*/
/*  background-clip: padding-box !important;*/
/*  cursor: default;*/
/*  color: transparent !important;*/
/*  -webkit-animation: 0.8s linear infinite alternate skeleton;*/
/*  animation: 0.8s linear infinite alternate skeleton;*/
/*  pointer-events: none;*/
/*  -webkit-user-select: none;*/
/*  -moz-user-select: none;*/
/*  -ms-user-select: none;*/
/*  user-select: none;*/
/*}*/
